import React, { FunctionComponent, useContext, useState } from 'react'
import './App.css'
import Log from './activities/Log'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import netlifyIdentity from 'netlify-identity-widget'
import UserContext from './user-context'

const App: FunctionComponent = () => {
  const [user, setUser] = useState<netlifyIdentity.User | null>(netlifyIdentity.currentUser());

  netlifyIdentity.on('login', setUser);
  netlifyIdentity.on('logout', () => {
    setUser(null);
  });

  return (<Box sx={{ flexGrow: 1 }}>
      <UserContext.Provider value={user}>
        <AppBar position='static'>
          <Toolbar>
            {/* <IconButton size='large' edge='start' color='inherit' aria-label='menu' sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton> */}
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              Log Activity
            </Typography>
            <AuthButton />
          </Toolbar>
        </AppBar>
        {user !== null && <Log />}
      </UserContext.Provider>
    </Box>
  )
}


const AuthButton: FunctionComponent = () => {
  const user = useContext(UserContext);
  return user !== null ? (
      <p>{user?.user_metadata?.full_name}<Button color='inherit' onClick={() => netlifyIdentity.logout()}>Sign out</Button></p>
    ) : (
      <Button color='inherit' onClick={() => netlifyIdentity.open()}>Login</Button>
    )
}

export default App
