import { Accordion, AccordionDetails, AccordionSummary, Slider, Input } from '@mui/material'
import debounce from 'lodash.debounce'
import React, { FunctionComponent, useState } from 'react'
import log from '../data/instance'
import { Activity } from '../data/types'

const debouncedUpdate = debounce(log.updateDay, 500)

interface Props {
  activity: Activity
  shown: boolean
  onShow: (day: Date) => void
}

const Day: FunctionComponent<Props> = ({ activity, shown, onShow }) => {
  const { completed, day, count, type } = activity
  const [value, setValue] = useState<number>(completed)
  const updateValue = (completed: number) => {
    activity.completed = completed
    debouncedUpdate(day, completed)
    setValue(completed)
  }

  const colour = completed === count ? 'green' : undefined

  return (
    <Accordion expanded={shown} onChange={() => onShow(day)} color={colour}>
      <AccordionSummary>{day.toLocaleDateString()}</AccordionSummary>
      <AccordionDetails>
        <Slider
          max={count}
          value={value}
          onChange={(_, newValue) => updateValue(Array.isArray(newValue) ? newValue[0] : newValue)}
        />
        <Input
          value={value}
          onChange={(e) => {
            const value = Number(e.target.value)
            if (isFinite(value)) {
              updateValue(value)
            }
          }}
        />
        Complete {count} {type}s today
      </AccordionDetails>
    </Accordion>
  )
}

export default Day
