import { endOfYesterday, isBefore, startOfToday, startOfTomorrow } from 'date-fns'
import add from 'date-fns/add'
import netlifyIdentity from 'netlify-identity-widget'

import { Activity, ActivityDoc } from './types'
import { ActivityGenerator } from './activityGenerator'

export interface Log {
  getNextDays(user: netlifyIdentity.User | null, count: number): Promise<Activity[]>
}

export class EsLog implements Log {
  constructor(private readonly activityGenerator: ActivityGenerator) {}

  async getNextDays(user: netlifyIdentity.User | null, count: number): Promise<Activity[]> {
    let headers: HeadersInit | undefined = undefined
    if (user !== null && user.token !== undefined) {
      headers = {
        Authorization: `Bearer ${user?.token?.access_token}`
      }
    }
    const response = await fetch('.netlify/functions/es-search', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        size: count + 2,
        sort: 'day',
        query: {
          bool: {
            must: [
              {
                range: {
                  day: {
                    gt: 'now-1d/d',
                    lt: `now+${count}d/d`,
                  },
                },
              },
            ],
          },
        },
      }),
    })

    const yesterday = endOfYesterday()
    const tomorrow = startOfTomorrow()
    const endOfPeriod = add(tomorrow, { days: count })

    const hits = (await response.json()).hits.hits as ActivityDoc[]
    const currentDays = hits
      .map(
        (h): Activity => ({
          ...h._source,
          day: new Date(h._source.day),
        }),
      )
      .filter(({ day }) => isBefore(yesterday, day) && isBefore(day, endOfPeriod))

    const requiredNewDays = count - currentDays.length
    if (requiredNewDays === 0) {
      return currentDays
    }

    const daysFrom = currentDays.length
      ? add(currentDays[currentDays.length - 1].day, { days: 1 })
      : startOfToday()
    const newDays = await this.buildDays(requiredNewDays, daysFrom)
    return [...currentDays, ...newDays]
  }

  async updateDay(day: Date, completed: number): Promise<void> {
    const response = await fetch('.netlify/functions/es-update', {
      method: 'POST',
      body: JSON.stringify({
        day,
        completed,
      }),
    })

    if (!response.ok) {
      throw new Error(`Failed to update days count [${response.json()}]`)
    }
  }

  private async buildDays(count: number, from: Date): Promise<Activity[]> {
    const newDays = this.activityGenerator.generate(count, from)

    const response = await fetch('.netlify/functions/es-bulk', {
      method: 'POST',
      body: JSON.stringify({
        operations: newDays.flatMap((day) => [{ index: {} }, day]),
      }),
    })

    if (!response.ok) {
      throw new Error(`Failed to index new activity days: [${response.json()}]`)
    }

    return newDays
  }
}
