import sample from 'lodash.sample'
import add from 'date-fns/add'

import { Activity } from './types'

export interface ActivityGenerator {
  generate(count: number, from: Date): Activity[]
}

export class RandomActivityGenerator implements ActivityGenerator {
  constructor(
    private readonly types: string[],
    private readonly countRange: { min: number; max: number },
  ) {}

  generate(count: number, from: Date): Activity[] {
    const types = Array(count)
      .fill(0)
      .map(() => sample(this.types) || this.types[0])
    const countIncrement = this.countRange.max - this.countRange.min

    return types.map(
      (type, day): Activity => ({
        type,
        day: add(from, { days: day }),
        count: this.countRange.min + Math.floor(Math.random() * countIncrement),
        completed: 0,
      }),
    )
  }
}
