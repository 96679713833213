import { CircularProgress } from '@mui/material'
import React, { Fragment, FunctionComponent, useState, useContext } from 'react'
import log from '../data/instance'
import { Activity } from '../data/types'
import Day from './Day'
import UserContext from '../user-context'

const Log: FunctionComponent = () => {
  const [activities, setActivities] = useState<Activity[]>()
  const [dayShown, setDayShown] = useState<Date>()
  const user = useContext(UserContext)

  if (activities && activities.length) {
    return (
      <Fragment>
        {activities.map((activity) => (
          <Day
            shown={dayShown === activity.day}
            onShow={setDayShown}
            activity={activity}
            key={activity.day.toLocaleDateString()}
          />
        ))}
      </Fragment>
    )
  }

  log.getNextDays(user, 7).then(setActivities)

  return <CircularProgress />
}

export default Log
